import React, { Component } from 'react'
import { Container, Row, Col, Spinner, Alert } from 'reactstrap'
import prevImg from 'img/vectores/flecha-izq.svg'
import nextImg from 'img/vectores/flecha-der.svg'
import backgroundImage from 'img/background-image.png'
import backgroundImageMobile from 'img/background-image-mobile.png'
import amenities1 from 'img/amenities1.png'
import amenities2 from 'img/amenities2.png'
import amenities3 from 'img/amenities3.png'
import amenities4 from 'img/amenities4.png'
import amenities5 from 'img/amenities5.png'
import amenities6 from 'img/amenities6.png'
import amenities7 from 'img/amenities7.png'
import amenities8 from 'img/amenities8.png'

import { Slide } from 'shared/components/common/ACarousel/types'
import { SearchTravel } from '../travel/components'
import { IndexLayoutProps, IndexLayoutState } from './types'
import { Travel } from 'modules/travel/models'
import { navigate } from 'gatsby'
import { ApiErrors } from 'core/services/ApiService/types'
import { ApiService } from 'core/services'
import './IndexLayout.css'
import { CaruselService } from 'services'

import { FacebookPixel, setupPixel } from 'core/utils'
import NewCarousel from './NewCarousel'

let ReactPixel: FacebookPixel

const controlImgs: any = {
  prev: prevImg,
  next: nextImg
}

const terminales = [
  {
    ciudad: 'Tijuana',
    direccion: 'Calle Ing. Juan Ojeda Robles 114, Buena Vista, CP. 22415, Tijuana, BC.'
  },
  {
    ciudad: 'Tecate',
    direccion: 'Blvd. Universidad 525, Lázaro Cárdenas, Tecate, Tecate, BC. CP. 21490'
  },
  {
    ciudad: 'Mexicali',
    direccion: 'Calzada Independencia 1201, frente a central de autobuses de Mexicali.'
  },
  {
    ciudad: 'San Luis Río Colorado',
    direccion: 'Avenida Obregón entre 28 y 29'
  },
  {
    ciudad: 'Cd. Obregón',
    direccion: 'Central de autobuses de Cd. Obregón, Sala 2.'
  },
  {
    ciudad: 'Los Mochis',
    direccion: 'Callejón Tenochtitlán 355 pte. entre Leyva y Zaragoza, frente a Restaurante Panamá.'
  },
  {
    ciudad: 'Guasave',
    direccion: 'Blvd. Central, col. Centro, CP. 81000, contra esquina del panteón, Guasave, Sinaloa.'
  },
  {
    ciudad: 'Culiacán',
    direccion: 'Blvd. Miguel Tamayo s/n, Desarrollo Urbano 3 Ríos, a lado de la USE, Culiacán, Sinaloa.'
  },
  {
    ciudad: 'Mazatlán',
    direccion: 'Carretera Internacional Km. 1205 s/n, Plaza San Ignacio, frente a ISSSTE, Mazatlán, Sin.'
  }
]

// Tu código de componente seguirá igual.

const amenidades = [
  { title: 'ASIENTOS CONFORTABLES', imageSrc: amenities1 },
  { title: 'ASIENTOS EXCLUSIVOS PARA MUJERES', imageSrc: amenities2 },
  { title: 'ASIENTOS EJECUTIVOS PARA PCCD', imageSrc: amenities3 },
  { title: 'AIRE ACONDICIONADO', imageSrc: amenities4 },
  { title: 'WI-FI', imageSrc: amenities5 },
  { title: 'PANTALLAS HD', imageSrc: amenities6 },
  { title: 'SANITARIOS', imageSrc: amenities7 },
  { title: 'TOMACORRIENTE', imageSrc: amenities8 }
]

class IndexLayout extends Component<IndexLayoutProps, IndexLayoutState> {
  subscribeForm = React.createRef()
  state: IndexLayoutState = {
    travelDataFetched: false,
    slides: [],
    slidDataFetched: false,
    isMobile: false
  }
  slides: Slide[] = []
  constructor(props: IndexLayoutProps) {
    super(props)
    // Inicializa el estado aquí
    this.state = {
      travelDataFetched: false,
      slides: [],
      slidDataFetched: false,
      isMobile: false
    }
    this.handleResize = this.handleResize.bind(this)
    this.onTravelSubmit = this.onTravelSubmit.bind(this)
    CaruselService.getImg()
      .then(data => {
        const DataImg: any[] = data[0]
        DataImg.forEach(slideImg => {
          const slidenew: Slide = {
            src: slideImg.Slide
          }
          this.slides.push(slidenew)
        })
        this.setState({ slides: this.slides, slidDataFetched: true })
      })
      .catch(err => {
        console.error(err)
      })
  }

  componentDidMount() {
    const { getTerminals, getTickets } = this.props
    this.handleResize()
    Promise.all([getTerminals(), getTickets()])
      .then(() => {
        this.setState({ travelDataFetched: true })
      })
      .catch(err => {
        if (err && err.status === ApiErrors.HANDLED_ERROR) {
          return this.setState({ travelError: err.message, travelDataFetched: true })
        }
        console.error('Error on getting terminals and tickets', err)
      })
    ReactPixel = setupPixel()
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    // Elimina el event listener antes de que el componente se desmonte
    window.removeEventListener('resize', this.handleResize)
  }

  sliderControl = (direction: string) => (fn: any) => (
    <a
      className={`carousel-control-${direction}`}
      onClick={e => {
        e.preventDefault()
        fn()
      }}
      role="AButton"
      tabIndex={0}
    >
      <img src={controlImgs[direction]} />
      <span className="sr-only">{direction}</span>
    </a>
  )

  onTravelSubmit(travel: Travel) {
    const { setTravelData, removeTravelData } = this.props
    removeTravelData()
    travel.step = 0
    travel.passengers = Travel.createPassengers(travel)
    setTravelData(travel)
    navigate('viaje')
  }

  onTrackingSearch = (code: string) => {
    navigate('rastreo', {
      state: { code }
    })
  }

  onSubscribeSubmit = (model: any) => {
    const { email } = model
    const newState: any = { subscribing: false }
    this.setState({ subscribing: true, subscribeError: '', subscribeSuccess: '' })
    ApiService.post<any, { email: string }>('customers/subscription', { email })
      .then(() => {
        newState.subscribeSuccess = 'A partir de este momento le llegarán promociones exclusivas a su correo.'
        ReactPixel?.track('Lead', { email })
        this.setState(newState)
      })
      .catch(err => {
        if (err.status && err.status === ApiErrors.HANDLED_ERROR) {
          newState.subscribeError = err.message
        } else {
          newState.subscribeError = `Error al subscribir. Intente de nuevo más tarde.`
        }
        this.setState(newState)
      })
  }

  onSubscribeValidForm = () => {
    this.setState({ canSubscribe: true })
  }

  onSubscribeInvalidForm = () => {
    this.setState({ canSubscribe: false })
  }

  handleResize() {
    this.setState({ isMobile: window.innerWidth <= 768 })
  }

  render() {
    const { terminals, special_tickets } = this.props
    const { travelDataFetched, travelError, isMobile } = this.state

    return (
      <>
        <div style={{ backgroundColor: '#E6E6E6' }}>
          <Row className="RowSlideOverflow">
            <Col xs={12} md={12} lg={12} xl={4} sm={12}>
              <Container
                className={travelDataFetched ? '' : 'text-center'}
                style={{
                  backgroundColor: 'white',
                  borderRadius: '40px',
                  width: isMobile ? '90vw' : '1200px',
                  maxWidth: '90vw',
                  marginTop: '50px',
                  padding: '20px',
                  boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.2)'
                }}
              >
                {travelDataFetched ? (
                  <>
                    {travelError ? (
                      <Alert color="danger" className="d-inline-block">
                        {travelError}
                      </Alert>
                    ) : null}
                    <SearchTravel
                      disabled={!!travelError}
                      onConfirm={this.onTravelSubmit}
                      terminals={terminals}
                      special_tickets={special_tickets}
                    />
                  </>
                ) : (
                  <Spinner color="danger" />
                )}
              </Container>
            </Col>
          </Row>

          <NewCarousel />

          <div id="destinos">
            {/* NUESTROS DESTINOS (MAPA) */}
            <Container style={{ maxWidth: '900px', margin: 'auto', paddingBottom: isMobile ? 0 : '30px' }}>
              <h4
                style={{
                  color: '#293890',
                  borderLeft: '20px solid #d91a5b',
                  paddingLeft: '20px',
                  textAlign: 'left',
                  marginRight: isMobile ? '0' : '600px',
                  marginTop: '100px',
                  width: isMobile ? '300px' : '400px',
                  maxWidth: '90vw',
                  lineHeight: '50px',
                  fontSize: isMobile ? '25px' : '30px'
                }}
              >
                NUESTROS DESTINOS
              </h4>
            </Container>
            <div
              style={{
                backgroundImage: `url(${isMobile ? backgroundImageMobile : backgroundImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                height: isMobile ? '65vh' : '120vh'
              }}
            />
          </div>
        </div>

        <div style={{ backgroundColor: '#293890', padding: '10px 0' }} id="amenidades">
          <Container style={{ maxWidth: '900px', margin: 'auto' }}>
            <h4
              style={{
                color: 'white',
                borderLeft: '20px solid #d91a5b',
                paddingLeft: '20px',
                textAlign: 'left',
                marginRight: isMobile ? '0' : '600px',
                lineHeight: '50px',
                marginBottom: '50px',
                fontSize: isMobile ? '25px' : '30px'
              }}
            >
              AMENIDADES
            </h4>
            <Row>
              {amenidades.map(amenidad => (
                <Col xs={6} md={3} key={amenidad.title}>
                  <div style={{ maxWidth: '150px', margin: 'auto', textAlign: 'center', marginTop: '20px' }}>
                    <img
                      src={amenidad.imageSrc}
                      alt={amenidad.title}
                      style={{ width: '100%', maxWidth: isMobile ? '100px' : '120px', height: 'auto' }}
                    />
                    <p style={{ color: 'white', fontWeight: '700', fontSize: '18px', textTransform: 'uppercase', marginTop: '8px' }}>
                      {amenidad.title}
                    </p>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </div>

        <div style={{ backgroundColor: '#293890', padding: '100px 0' }} id="terminales">
          <Container style={{ maxWidth: '900px', margin: 'auto' }}>
            <h4
              style={{
                color: 'white',
                borderLeft: '20px solid #d91a5b',
                paddingLeft: '20px',
                textAlign: 'left',
                marginRight: isMobile ? '0' : '600px',
                lineHeight: '50px',
                marginBottom: '50px',
                fontSize: isMobile ? '25px' : '30px'
              }}
            >
              TERMINALES
            </h4>
            <Row>
              {terminales.map(terminal => (
                <Col xs={12} md={6} key={terminal.ciudad}>
                  <div style={{ maxWidth: '350px', margin: 'auto' }}>
                    <p
                      style={{
                        color: 'white',
                        fontWeight: '700',
                        fontSize: isMobile ? '20px' : '25px',
                        textTransform: 'uppercase',
                        marginBottom: 0
                      }}
                    >
                      {terminal.ciudad}
                    </p>
                    <p style={{ color: 'white', fontWeight: '300', fontSize: isMobile ? '16px' : '18x' }}>{terminal.direccion}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </>
    )
  }
}

export default IndexLayout
