import React, { useState } from 'react'
import { Carousel, CarouselItem, CarouselIndicators, CarouselControl } from 'reactstrap'
import banner1 from 'img/banner1.png'
import banner2 from 'img/banner2.png'
import banner3 from 'img/banner3.png'
import banner4 from 'img/banner4.png'

const items = [
  { src: banner1, altText: 'Slide 1', link: '/descarga' },
  { src: banner2, altText: 'Slide 2' },
  { src: banner3, altText: 'Slide 3' },
  { src: banner4, altText: 'Slide 4' }
]

const NewCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = (newIndex: any) => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const slides = items.map(item => {
    return (
      <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={item.src}>
        <img src={item.src} alt={item.altText} style={{ width: '100%', height: '100%' }} />
      </CarouselItem>
    )
  })

  return (
    <div
      style={{
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '80px',
        paddingBottom: '20px'
      }}
    >
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      </Carousel>
    </div>
  )
}

export default NewCarousel
